<!--
 * @Description: 任务项详情
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-11-24 13:56:58
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 -->
          <template>
            <div class="baseInfo-content-wrap">
              <div class="baseInfo-box basicForm">
                <div class="edit-title">基本信息</div>
                <el-form
                  label-width="140px"
                  label-position="right"
                  class="mbForm"
                  style="margin-top:10px;"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="工单ID">
                        {{ basicInfo.itemId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="调度任务ID">
                        {{ basicInfo.scheduleId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="调度信息ID">
                        {{ basicInfo.scheduleInfoId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务类型">
                        {{ basicInfo.taskFirstClassName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务单号">
                        {{ basicInfo.taskNumber }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="客户名称">
                        {{ basicInfo.corpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="场景名称">
                        {{ basicInfo.sceneName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-show="basicInfo.taskSecondClass == 3">
                      <el-form-item label="新场景名称">
                        {{ basicInfo.newSceneName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="场景类型">
                        {{ basicInfo.sceneTypeName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="车牌颜色/地址">
                        {{
                          basicInfo.sceneType == 1
                            ? basicInfo.plateColorName
                            : basicInfo.sceneAddress
                        }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="指派人">
                        {{ basicInfo.assignorName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="指派时间">
                        {{ basicInfo.assignTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="完成时间">
                        {{ basicInfo.finishTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="作业时间">
                        {{ basicInfo.fixDate }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="工程师">
                        {{ basicInfo.engineerrpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="工程师电话">
                        {{ basicInfo.engineerrpPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="关闭任务项原因">
                        {{ basicInfo.closeReasonStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="关闭任务项备注">
                        {{ basicInfo.closeRemark }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="问题类型">
                        {{ basicInfo.questionClass }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="问题描述">
                        {{ basicInfo.quesName }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="诊断问题">
                        {{ basicInfo.quesReason }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="处理方案">
                        {{ basicInfo.solution }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 作业明细 start -->
              <div class="task-list-content">
                <div class="edit-title">作业明细</div>
                <el-table :data="basicInfo.detailRespVOList" max-height="350">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in installDetailColumn"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 费用明细 start -->
              <div class="task-list-content">
                <div class="edit-title">费用明细</div>
                <el-table :data="feeData" max-height="350">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in feeColumn"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                    <template slot-scope="{ row }">
                      <span v-if="column.fieldName === 'feeMap.urgent'">
                        <el-input
                          v-model="row.feeMap.urgent"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span v-else-if="column.fieldName === 'feeMap.material'">
                        <el-input
                          v-model="row.feeMap.material"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span v-else-if="column.fieldName === 'feeMap.express'">
                        <el-input
                          v-model="row.feeMap.express"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span
                        v-else-if="column.fieldName === 'feeMap.nonStandard'"
                      >
                        <el-input
                          v-model="row.feeMap.nonStandard"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span v-else-if="column.fieldName === 'feeMap.project'">
                        <el-input
                          v-model="row.feeMap.project"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span
                        v-else-if="
                          column.fieldName === 'feeMap.complaintsPraise'
                        "
                      >
                        <el-input
                          v-model="row.feeMap.complaintsPraise"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span
                        v-else-if="column.fieldName === 'feeMap.workOrderLevel'"
                      >
                        <el-input
                          v-model="row.feeMap.workOrderLevel"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span v-else-if="column.fieldName === 'feeMap.other'">
                        <el-input
                          v-model="row.feeMap.other"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span v-else-if="column.fieldName === 'feeMap.adjust'">
                        <el-input
                          v-model="row.feeMap.adjust"
                          :disabled="entry == 'detail' ? true : false"
                        ></el-input>
                      </span>
                      <span
                        v-else-if="column.fieldName === 'firstAdjustReason'"
                      >
                        <e6-vr-select
                          v-model="row.firstAdjustReason"
                          :data="adjustReasonFirstList"
                          placeholder="一级调整原因"
                          title="一级调整原因"
                          clearable
                          virtual
                          :is-title="true"
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                          multiple
                          @change="changeReason"
                          @clear="clearReason"
                          :disabled="entry == 'detail' ? true : false"
                        ></e6-vr-select>
                        <e6-vr-select
                          v-model="row.adjustReason"
                          :data="adjustReasonSecondList"
                          placeholder="二级调整原因"
                          title="二级调整原因"
                          clearable
                          virtual
                          multiple
                          :is-title="true"
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                          :disabled="entry == 'detail' ? true : false"
                        ></e6-vr-select>
                      </span>
                      <span v-else>
                        {{ at(row, column.fieldName)[0] }}
                      </span>
                    </template>
                  </el-table-column>
                </el-table>
                <el-form
                  :inline="true"
                  label-width="160px"
                  :model="feeInfoForm"
                  style="margin-top:20px;"
                >
                  <el-form-item prop="sumFee" label="总费用(元):">
                    {{ feeInfoForm.sumFee }}
                  </el-form-item>
                  <el-form-item prop="serviceFee" label="服务商服务费(元):">
                    {{ feeInfoForm.serviceFee }}
                  </el-form-item>
                  <el-form-item prop="settleMonth" label="结算月份:">
                    <el-date-picker
                      v-model="feeInfoForm.settleMonth"
                      type="month"
                      placeholder="选择月"
                      value-format="yyyy-MM"
                      :disabled="entry == 'detail' ? true : false"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer" v-if="entry == 'edit' ? true : false">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="confirm">提交</el-button>
        </div>
      </section>
      <!-- 表格 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import { at } from "lodash";
import {
  getTaskItemDetail,
  getManualDetail,
  updateManual,
  findDownList
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "serviceChargesDetail",
  data() {
    return {
      at,
      loading: false,
      entry: "",
      basicInfo: {}, //基本信息
      detailRespVOList: [], //作业明细数据
      //新装加装作业明细表头
      installDetailColumn1: [
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equipOpName",
          display: true,
          fieldLabel: "设备操作",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "installStatusName",
          display: true,
          fieldLabel: "安装状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equCombination",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "insbodyName",
          display: true,
          fieldLabel: "安装位置",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        }
      ],
      //拆卸维修巡检作业明细表头
      installDetailColumn2: [
        {
          fieldName: "equipOpName",
          display: true,
          fieldLabel: "设备操作",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "cardOpName",
          display: true,
          fieldLabel: "卡操作",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equCombination",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "新设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "oldEquipCode",
          display: true,
          fieldLabel: "旧设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "新SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "oldSimNo",
          display: true,
          fieldLabel: "旧SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        }
      ],
      feeColumn: [
        {
          fieldName: "payCompanyName",
          display: true,
          fieldLabel: "公司支付主体",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.impl",
          display: true,
          fieldLabel: "实施费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.addParts",
          display: true,
          fieldLabel: "加装配件补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.uninstall",
          display: true,
          fieldLabel: "拆机费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.reach",
          display: true,
          fieldLabel: "上门费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.night",
          display: true,
          fieldLabel: "夜间费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.road",
          display: true,
          fieldLabel: "路补",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.empty",
          display: true,
          fieldLabel: "空跑费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.urgent",
          display: true,
          fieldLabel: "加急费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.material",
          display: true,
          fieldLabel: "材料补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.express",
          display: true,
          fieldLabel: "快递补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.nonStandard",
          display: true,
          fieldLabel: "非标补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.project",
          display: true,
          fieldLabel: "项目奖罚",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.complaintsPraise",
          display: true,
          fieldLabel: "投诉/表扬/培训激励",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.workOrderLevel",
          display: true,
          fieldLabel: "工单等级奖",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.other",
          display: true,
          fieldLabel: "其他费用",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.quality",
          display: true,
          fieldLabel: "工程质检扣款",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.assign",
          display: true,
          fieldLabel: "工单下派",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.preAppoint",
          display: true,
          fieldLabel: "预约响应",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.reachOutTime",
          display: true,
          fieldLabel: "准时到达",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.delay",
          display: true,
          fieldLabel: "履约时效",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.adjust",
          display: true,
          fieldLabel: "差额调整",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstAdjustReason",
          display: true,
          fieldLabel: "调整原因",
          width: 400,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      adjustReasonFirstList: [],
      adjustReasonSecondList: [],
      secondReasonList: [],
      feeData: [],
      feeInfoForm: {
        settleMonth: "",
        serviceFee: "",
        sumFee: ""
      },
      settleId: ""
    };
  },
  mixins: [base, goBack],
  components: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  computed: {
    installDetailColumn() {
      //作业明细表头
      let column = [];
      if ([1, 2, 7, 19, 20].indexOf(this.basicInfo.taskSecondClass) > -1) {
        column = this.installDetailColumn1;
      } else {
        column = this.installDetailColumn2;
      }
      return column;
    }
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.itemId = hasParamsRoute.params.itemId;
      this.entry = hasParamsRoute.params.entry;
      this.settleId = hasParamsRoute.params.settleId;
      if (this.itemId) {
        this.initData();
      }
    },
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          getTaskItemDetail({ itemId: this.itemId }),
          getManualDetail({ itemId: this.itemId, settleId: this.settleId }),
          findDownList(["firstAdjustReason"])
        ];
        let [res1, res2, res3] = await Promise.all(promiseList);
        this.basicInfo = this.getFreezeResponse(res1, {
          path: "data"
        });
        let firstAdjustReason =
          res2.data.reasonRespVOList && res2.data.reasonRespVOList.length
            ? res2.data.reasonRespVOList.map(item => item.firstAdjustReason)
            : [];
        let adjustReason =
          res2.data.reasonRespVOList && res2.data.reasonRespVOList.length
            ? res2.data.reasonRespVOList.map(item => item.adjustReason + "")
            : [];
        this.feeData = [
          {
            ...res2.data,
            firstAdjustReason: [...new Set(firstAdjustReason)],
            adjustReason
          }
        ];
        this.feeInfoForm.settleMonth = res2.data.settleMonth;
        this.feeInfoForm.sumFee = res2.data.sumFee;
        this.feeInfoForm.serviceFee = res2.data.serviceFee;
        this.adjustReasonFirstList = this.getFreezeResponse(res3, {
          path: "data.firstAdjustReason"
        });
        this.getSecondReasons(
          this.adjustReasonFirstList.map(item => item.codeValue)
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    //修改一级原因
    changeReason() {
      this.handleReasonList();
    },
    //处理联动数据
    handleReasonList() {
      let feeData = this.feeData;
      let adjustReason = feeData[0].adjustReason;
      let secondReasons = this.secondReasonList;
      let newArr = [];
      feeData[0].firstAdjustReason.map(key => {
        newArr.push(secondReasons[key]);
      });
      let adjustReasonSecondList = newArr.length
        ? newArr.reduce((a, b) => {
            return a.concat(b);
          })
        : [];
      this.adjustReasonSecondList = adjustReasonSecondList;
      let newSecondReason = adjustReasonSecondList.filter(
        ele => adjustReason.indexOf(ele.codeValue) !== -1
      );
      feeData[0].adjustReason = newSecondReason.map(item => item.codeValue);
      this.feeData = feeData;
    },
    clearReason() {
      this.handleReasonList();
    },
    async getSecondReasons(names) {
      try {
        let res = await findDownList(names);
        if (res.code == "OK") {
          this.secondReasonList = res.data;
          this.handleReasonList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    confirm() {
      let feeData = this.feeData[0];
      if (feeData.firstAdjustReason.length && !feeData.adjustReason.length) {
        this.$message.warning("选择一级原因必须选二级原因，请检查后重新提交");
        return;
      }
      this.updateManualReq();
    },
    async updateManualReq() {
      try {
        this.loading = true;
        let feeData = this.feeData[0];
        let arr = [];
        for (let key in this.secondReasonList) {
          arr.push(this.secondReasonList[key]);
        }
        let allReasonSecondList = arr.reduce((a, b) => {
          return a.concat(b);
        });
        let filterArr = allReasonSecondList.filter(
          ele => feeData.adjustReason.indexOf(ele.codeValue) !== -1
        );
        let reasonRespVOList = filterArr.map(item => {
          return Object.assign(
            {},
            {
              adjustReason: item.codeValue,
              adjustReasonStr: item.codeName
            }
          );
        });
        let form = {
          ...feeData,
          ...feeData.feeMap,
          reasonRespVOList
        };
        delete form.feeMap;
        let res = await updateManual({
          itemIdList: [this.itemId],
          ...form,
          settleMonth: this.feeInfoForm.settleMonth,
          settleIds: [this.settleId],
          operationType: "update"
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}

.baseInfo-content-wrap {
  width: 100%;
  .baseInfo-box {
    width: 100%;
    .edit-title {
      width: 100%;
      margin-top: 10px;
    }
    /deep/.el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin: 0;
    }
    /deep/.el-form-item--mini .el-form-item__label {
      line-height: 40px;
    }
    /deep/.el-form-item--mini .el-form-item__content {
      line-height: 40px;
    }
    .col {
      height: 40px;
      border-bottom: 1px solid #f0f0f0;
    }
    .col2 {
      height: 80px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .task-list-content {
    margin-top: 20px;
    .label-button-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .operate-action {
        height: 48px;
        .iBtn {
          height: 28px;
          margin-right: 10px;
          color: #46bfea;
          font-size: 12px;
          line-height: 28px;
          font-style: normal;
          background-color: #daf2fb;
          text-align: center;
          padding: 0 10px;
          cursor: pointer;
          border-radius: 3px;
        }
      }
    }
  }
}
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 20px;
    overflow-y: auto;
    max-height: 600px;
    // .el-input {
    //   width: 220px;
    // }
  }
}
.imgs {
  display: flex;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-direction: row;
  // justify-content: space-between;
  .img-item {
    margin-bottom: 20px;
    margin-right: 15px;
    & > .img {
      width: 240px;
      height: 160px;
      border-radius: 20px;
    }
    .picture-name {
      margin-top: 20px;
      font-size: 16px;
      color: #5f5f5f;
      text-align: center;
    }
    .picture-time {
      font-size: 14px;
      color: #909195;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .picture-score {
      text-align: center;
      .el-input {
        width: 130px;
        margin: 0 auto;
      }
    }
  }
  .img-hidden {
    width: 240px;
    height: 0px;
  }
}
.image__preview {
  margin: 0 auto;
  width: 500px;
  height: 500px;
  .el-image {
    width: 500px !important;
    height: 500px !important;
    img {
      width: 500px !important;
      height: 500px !important;
    }
  }
}
</style>
